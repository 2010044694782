.ol-overlay-container {
    padding: 0;
}

.ol-scale-line {
    background: #008000AA;
}

.ol-zoom {
    top: 3em;
    right: 0.5em;
    left: unset;
}

.ol-control button {
    width: 1.8em;
    height: 1.8em;
    background: #008000DA;
}

.ol-control button:hover {
    background: #008000;
}

.ol-control button:focus {
    background: #008000;
}
