.ol-overlay-container {
    background-color: #00000000;
    padding: 12px;
    border-radius: 12px;
}

.ol-overlay-container .body {
    font-size: 14px;
}

.popover-like-box {
    top: 100px; /* Adjust as needed */
    left: 200px; /* Adjust as needed */
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    z-index: 999; /* Ensure it appears above other elements */
}

.popover-content {
    max-width: 300px;
}

.popover-tail {
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent #fff transparent;
    top: -20px; /* Adjust as needed to position the tail */
    left: 50%;
    transform: translateX(-50%);
}




