/* Customize map container */
.ol-map {
    width: 100%;
    height: 100%;
}

.ol-control {
    position: absolute;
}

/* Customize full screen control */
.ol-full-screen {
    top: 0.5em;
    right: 1em;
    background-color: white;
    padding: 0px;
}

.ol-full-screen button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
}

/* Customize zoom in and out */
.ol-zoom-in,
.ol-zoom-out {
    width: 30px;
    height: 30px;
    background-color: white;
    padding: 0px;
    border: none;
    margin-top: 10px;
    margin-left: 10px;
}

.ol-custom-button {
    background-color: blue;
    top: 1em;
    left: 1em;
}